<template>
    <card-component title="Edit User" icon="account-circle">
        <b-field horizontal label="Name">
            <b-input v-model="form.name" name="name" required/>
        </b-field>
        <b-field horizontal label="E-mail">
            <b-input v-model="form.email" name="email" type="email" required/>
        </b-field>

        <div v-can="'manage all users'">
            <hr>
            <b-field horizontal>
                <template slot="label">
                    <b-icon icon="lock" size="is-small"></b-icon>
                   Roles
                </template>
                <b-switch label="Admin" v-model="form.superadmin">Admin</b-switch>
                <b-switch label="Technician" v-model="form.technician">Technician</b-switch>
              <b-switch label="Technician" v-model="form.meldkamer">Meldkamer</b-switch>


            </b-field>
        </div>

        <hr>
        <b-field horizontal>
            <div class="control">
                <button class="button is-primary" @click="submit" :class="{'is-loading':isLoading}">
                    Submit
                </button>
            </div>
        </b-field>


    </card-component>
</template>

<script>
    import { mapState } from 'vuex'
    import CardComponent from '@/components/CardComponent'

    export default {
        name: 'UserFormComponent',
        components: {
            CardComponent
        },
        props: ['user'],

        data () {
            return {
                isFileUploaded: false,
                isLoading: false,
                form: {
                    name: null,
                    email: null
                }
            }
        },

        mounted () {
            this.form.name = this.user.name
            this.form.email = this.user.email
            if (this.$gates.hasPermission('manage all users') || this.$gates.isSuperUser()) {
                this.form.superadmin = this.user.superadmin
                this.form.technician = this.user.technician
                this.form.meldkamer = this.user.meldkamer
              }
        },
        methods: {
            submit () {
                this.isLoading = true
                this.$store.dispatch('users/updateUser', { user_id: this.user.id, data: this.form })
                    .then(response => {
                        this.$emit('update');

                        this.$buefy.toast.open({
                            message: 'User updated.',
                            type: 'is-success'
                        })

                        this.isLoading = false


                    }).catch((err) => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                    this.isLoading = false

                })

            }
        },
        watch: {
            name (newValue) {
                this.form.name = newValue
            },
            email (newValue) {
                this.form.email = newValue
            }
        }
    }
</script>
